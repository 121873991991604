import {DataGridPro, GridToolbarContainer, useGridApiContext} from "@mui/x-data-grid-pro";
import React, {useState, useCallback} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const FileGrid = (props) => {
    const {
        columns,
        rows,
        lookupError,
        samples,
        studyName,
        addedStudies,
        newStudy,
        setStudy,
        setAddedStudies,
        setFieldValue,
        validateField,
        currentDataSource,
        upload,
        uploading
    } = props;
    let workingSamples = samples !== undefined ? Array.from(samples) : [];
    const [selectedSamples, setSelectedSamples] = useState([]);
    let tableHeight = 750;
    let pageSize = rows.length > 50 ? 50 : 25;
    let addSampleButtonText = "Add Samples";
    const isFileUpload = currentDataSource === "fileUpload";

    if (isFileUpload) {
        addSampleButtonText = "Upload and " + addSampleButtonText;
    }

    const handleCellEdit = useCallback((params) => {
        const id = params.id;
        const key = params.field;
        rows[id][key] = params.value;
        validateField('samples');
    }, []);

    const handleAddSamples = (apiRef) => {
        updateSamples();
        apiRef.current.setRowSelectionModel([]);
        setAddedStudies(addedStudies => [...addedStudies, studyName])
        console.log(addedStudies);
        if (addedStudies.length > 0) {
            setFieldValue('study', newStudy);
            setStudy(newStudy);
            setFieldValue('isNewStudy', true);
            setFieldValue('isMultiStudy', true);
            setFieldValue('jobOptions.runBatchCorrection', true);
        }
    }

    const updateSamples = () => {
        selectedSamples.forEach((selectedSample) => {
            if (!workingSamples.includes(selectedSample)) {
                selectedSample['sourceStudyId'] = studyName;
                workingSamples.push(selectedSample);
            }
        });
        setFieldValue('samples', workingSamples);
        validateField('samples');
    }

    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer>
                <Button
                    variant="contained"
                    sx={{m: 1}}
                    onClick={async () => {
                        if (isFileUpload) {
                            await upload();
                        }
                        handleAddSamples(apiRef);
                    }}
                    disabled={!!(isFileUpload && selectedSamples.length <= 0 && !uploading)}
                >
                    {addSampleButtonText}
                </Button>
            </GridToolbarContainer>
        )
    }

    return (
        <>
            {lookupError &&
                <>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Typography variant="h5">
                            {lookupError}
                        </Typography>
                    </Box>
                </>
            }
            {!lookupError &&
                <DataGridPro
                    columns={columns}
                    rows={rows}
                    isRowSelectable={(params) => params.row.isSelectable}
                    pageSize={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    checkboxSelection={true}
                    disableSelectionOnClick
                    slots={{
                        toolbar: CustomToolbar
                    }}
                    sx={{
                        height: tableHeight, mr: 2, mb: 1, borderRadius: "15px"
                    }}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectedSamples(newSelectionModel.map(i => rows[i]));
                    }}
                    onCellEditCommit={handleCellEdit}
                />
            }
        </>
    )
}

export {FileGrid}